import React, {useEffect, useState} from 'react';
import axios from 'axios';
import './../styles/weather.css'

const apiHost = process.env.REACT_APP_OPEN_WEATHER_API_HOST;
const apiKey = process.env.REACT_APP_OPEN_WEATHER_API_KEY;

const Weather = ({city}) => {
    const [weatherData, setWeatherData] = useState(null);

    useEffect(() => {
        const apiUrl = `${apiHost}/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric`;

        axios.get(apiUrl)
            .then(response => {
                setWeatherData(response.data);
            })
            .catch(error => {
                console.error('Error during weather loading:', error);
            });
    }, [city]);

    return (
        <div>
            {weatherData ? (
                <div className="card mt-3 mx-auto weatherContainer">
                    <div className="card-body text-center">
                        <img
                            src={`http://openweathermap.org/img/w/${weatherData.weather[0].icon}.png`}
                            alt="weather status icon"
                            className="weather-icon"
                        />
                        <p className="h2">
                            {weatherData.main.temp}&deg; C
                        </p>
                        <p className="h5">
                            <strong>{city}</strong>
                        </p>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <p>
                                    {'Minima: '}
                                    <strong>
                                        {weatherData.main.temp_min}&deg; C
                                    </strong>
                                </p>
                                <p>
                                    {'Massima: '}
                                    <strong>
                                        {weatherData.main.temp_max}&deg; C
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p>Caricamento...</p>
            )}
        </div>
    );
};

export default Weather;