import React from 'react'
import Header from '../components/Header'
import WeddingForm from "../components/WeddingForm";

const ParticipationConfirm = () => {
    return (
        <div>
            <Header fixed={false} />
            <WeddingForm />
        </div>
    );
}

export default ParticipationConfirm;