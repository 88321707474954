import React from "react";
import './../styles/footer.css'
import { FaFacebook, FaInstagram } from 'react-icons/fa';

const Footer = () => {

    return (
        <footer className={`footer`}>
            <div className={"footer-div"}>
                © 2023. Design By Gianpaolo Caprara. All Rights Reserved.
                <div className="social-icons">
                    <a href="https://www.facebook.com/gianpaolo.caprara/">
                        <FaFacebook className="icon facebook" />
                    </a>
                    <a href="https://www.instagram.com/gianpcaprara/">
                        <FaInstagram className="icon instagram" />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;