import React from 'react'
import Header from '../components/Header'
import Location from "../components/Location";
import Information from "../components/Information";

const WhereAndWhen = () => {
    return (
        <div>
            <Header fixed={false} />
            <Location />
            <Information />
        </div>
    );
}

export default WhereAndWhen;