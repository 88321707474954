import React from 'react'
import Header from '../components/Header'
import Contact from "../components/Contact";

const ContactUs = () => {
    return (
        <div>
            <Header fixed={false} />
            <Contact />
        </div>
    );
}

export default ContactUs;