import React from 'react';
import {Route, Routes} from 'react-router-dom'
import Home from "./pages/Home";
import './App.css';
import ParticipationConfirm from "./pages/ParticipationConfirm";
import ContactUs from "./pages/ContactUs";
import WhereAndWhen from "./pages/WhereAndWhen";
import WeddingNotes from "./pages/WeddingNotes";

const App = () => {
    return (
        <div className="max-w-7xl mx-auto">
            <Routes>
                <Route path='/' element={<Home/>}></Route>
                <Route path='/location' element={<WhereAndWhen/>}></Route>
                <Route path='/confirm' element={<ParticipationConfirm/>}></Route>
                <Route path='/notes' element={<WeddingNotes/>}></Route>
                <Route path='/contactUs' element={<ContactUs/>}></Route>
            </Routes>
        </div>
    );
}

export default App;