import React from 'react';
import { useState, useEffect } from 'react';
import '../styles/countdown.css';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

const Countdown = () => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const deadline = "2024-09-15T17:00:00";

    const getTime = () => {
        const time = Date.parse(deadline) - Date.now();

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    useEffect(() => {
        const interval = setInterval(() => getTime(deadline), 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={"countdown-page-container"}>
            <p className="countdown-date">Domenica 15 Settembre 2024</p>
            <p className="countdown-location">TENUTA SERRADESCA</p>
            <p className="countdown-location">Via Serradesca, 6, 24020 Scanzorosciate (BG)</p>
            <div className="countdown" role="timer">
                <div className="countdown-box">
                    <div className="countdown-square">
                        <p id="day" className="countdown-number">{days < 10 ? "0" + days : days}</p>
                        <span className="countdown-label">DAYS</span>
                    </div>
                </div>
                <div className="countdown-box">
                    <div className="countdown-square">
                        <p id="hour" className="countdown-number">{hours < 10 ? "0" + hours : hours}</p>
                        <span className="countdown-label">HOURS</span>
                    </div>
                </div>
                <div className="countdown-box">
                    <div className="countdown-square">
                        <p id="minute" className="countdown-number">{minutes < 10 ? "0" + minutes : minutes}</p>
                        <span className="countdown-label">MINS</span>
                    </div>
                </div>
                <div className="countdown-box">
                    <div className="countdown-square">
                        <p id="second" className="countdown-number">{seconds < 10 ? "0" + seconds : seconds}</p>
                        <span className="countdown-label">SECS</span>
                    </div>
                </div>
            </div>
            <AddToCalendarButton
                name="Matrimonio Gianpaolo & Martina"
                options={['Apple','Google']}
                location="Tenuta Serradesca, Scanzorosciate"
                startDate="2024-09-15"
                endDate="2024-09-16"
                startTime="17:20"
                endTime="02:00"
                timeZone="Europe/Rome"
            ></AddToCalendarButton>
        </div>
    );
};

export default Countdown;