import React from 'react'
import meAndYou from '../images/gianp_e_marti.jpg'
import '../styles/presentation.css'

const Presentation = () => {
    return (
        <div>
            <img src={meAndYou} alt="Gianp&Marti" className={"presentation-image"}/>
        </div>
    );
}

export default Presentation;