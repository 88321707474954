import React from 'react'
import './../styles/iban.css'

const Iban = () => {
    return (
        <div className="weddingNotes">
            <div className={"iban"}>
                <h1>Per il nostro viaggio di nozze abbiamo scelto di esplorare le coste della Grecia e della Turchia durante una meravigliosa crociera!</h1>
                <p>Ciò che conta di più per noi è condividere questo momento insieme a voi. Nel caso in cui desideriate farci un regalo, saremmo felici se vorrete contribuire a rendere ancora più memorabile il nostro viaggio di nozze.</p>
                <p>Intestato a: Caprara Gianpaolo Piluso Martina</p>
                <p>IBAN: IT31D0623020413000047676673</p>
                <p>Banca Crédit Agricole</p>
                <h2>Causale: Matrimonio Martina e Gianpaolo</h2>
            </div>
        </div>
    );
}

export default Iban;