import React, { useEffect, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import './../styles/ourstory.css'
import serradesca from '../images/serradesca.png'
import terme from '../images/terme.jpg'
import ragu from '../images/ragu.jpg'
import convivenza from '../images/convivenza.jpg'
import matilde from '../images/matilde.jpeg'
import FavoriteIcon from '@mui/icons-material/Favorite';


const events = [
    {
        date: '31 Ottobre 2018',
        title: 'La prima volta che ci siamo incontrati',
        description: 'In una delle prime sere fredde dell\'autunno, precisamente ad Halloween, incrociamo per la prima volta i nostri sguardi.',
        image: terme,
    },
    {
        date: '12 Settembre 2019',
        title: 'Iniziamo la convivenza... Aiuto!',
        description: 'Dopo i primi mesi della nostra relazione, vissuti al massimo, decidiamo di fare un primo passo: andiamo a vivere insieme. Da lì niente sarà più come prima...',
        image: convivenza,
    },
    {
        date: '16 Marzo 2021',
        title: 'Arriva la piccola peste',
        description: 'Dopo il terribile 2020 una luce entra nella nostra casa: Ragù. Grazie a lui capiamo cosa voglia dire l\'amore incondizionato.',
        image: ragu,
    },
    {
        date: '08 Agosto 2023',
        title: 'La nostra meraviglia, Matilde',
        description: 'La nostra famiglia si allarga: arriva la nostra piccolina Mati, che ci rivoluzionerà la vita con i suoi sguardi e i suoi sorrisi. E anche con tanti pannolini cambiati!',
        image: matilde,
    },
    {
        date: '15 Settembre 2024',
        title: 'Ci sposiamo!',
        description: 'Ed eccoci qua: dopo tantissime peripezie ci sposiamo! Via alla festa!!',
        image: serradesca,
    }
];

const OurStory = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={"our-story"}>
            <h1 className={"title"}>Our Story</h1>
            <Timeline position="alternate">
                {events.map((event, index) => (
                    <TimelineItem key={index}>
                        <TimelineOppositeContent color="text.secondary">
                            <div className="timeline-image">
                                <img src={event.image} alt="Serradesca" className="timeline-image" />
                            </div>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot className={"timeline-dot"} />
                            <TimelineConnector className="timeline-connector" />
                        </TimelineSeparator>
                        <TimelineContent className={index % 2 === 0 ? 'timeline-content' : 'timeline-content right'}>
                            <span className="timeline-title">{event.title}</span>
                            <br />
                            <p className="timeline-date">{event.date}</p>
                            <span className="timeline-description">{event.description}</span>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
            {screenWidth <= 844 && (
                <div>
                    <FavoriteIcon className="heart-icon" />
                    <a className="menu-item" href="/confirm">CONFERMA PARTECIPAZIONE</a>
                    <br></br>
                    <FavoriteIcon className="heart-icon" />
                    <a className="menu-item" href="/notes">NOTE DEGLI SPOSI</a>
                </div>
            )}
        </div>
    );
};

export default OurStory;