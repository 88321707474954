import React from 'react'
import './../styles/interlude.css'
import wedding_flower from "../images/wedding_flower.png";

const Interlude = () => {
    return (
        <div className={"interlude"}>
            <img src={wedding_flower} alt="Flower" className="flowerImage"/>
        </div>
    );
}

export default Interlude;