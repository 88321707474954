import React, { useState } from 'react';
import './../styles/weddingForm.css'
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay-ts';
import BeatLoader from 'react-spinners/BeatLoader';

const apiHost = process.env.REACT_APP_API_HOST;

const WeddingForm = () => {
    const [name, setName] = useState('');
    const [attendance, setAttendance] = useState('');
    const [companion, setCompanion] = useState('');
    const [intolerances, setIntolerances] = useState('');
    const [intoleranceList, setIntoleranceList] = useState('');
    const [structure, setStructure] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        console.log('Calling server with data:', {
            name,
            attendance,
            companion,
            intolerances,
            intoleranceList,
            structure
        });
        await axios.post(`${apiHost}/api/v1/wedding/confirm`, {
            name,
            attendance,
            companion,
            intolerances,
            intoleranceList,
            structure
        });
        setLoading(false);
        setSubmitted(true);
    };

    const handleAttendanceChange = (e) => {
        setAttendance(e.target.value);
    };

    const handleIntolerancesChange = (e) => {
        setIntolerances(e.target.value);
    };

    const handleStructureChange = (e) => {
        setStructure(e.target.value);
    };

    return (
        <LoadingOverlay
            active={isLoading}
            spinner={<BeatLoader color="#cfba1a" />}
            text='Attendere prego...'
        >
            <div className={"weddingPage"}>
                <div className="weddingForm">
                    {submitted ? (
                        <>
                            <h2>Partecipazione confermata!</h2>
                            <h3>Grazie per averci detto se verrai al nostro matrimonio!</h3>
                        </>
                    ) : (
                        <>
                            <h2>Conferma la tua partecipazione</h2>
                            <h3>Ti preghiamo di confermare entro e non oltre il 1 Agosto 2024</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="formRow">
                                    <input
                                        type="text"
                                        placeholder={"Nome e Cognome"}
                                        id="nameInput"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="formRow">
                                    <select value={attendance} onChange={handleAttendanceChange} required>
                                        <option value="">Parteciperai?</option>
                                        <option value="yes">Si</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                                {attendance === 'yes' && (
                                    <>
                                        <div className="formRow">
                                            <textarea
                                                placeholder={"Nomi Accompagnatori (separati da virgola)"}
                                                id="companionInput"
                                                value={companion}
                                                onChange={(e) => setCompanion(e.target.value)} />
                                        </div>
                                        <div className="formRow">
                                            <select value={intolerances} onChange={handleIntolerancesChange} required>
                                                <option value="">Hai restrizioni alimentari?</option>
                                                <option value="yes">Si</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        {intolerances === 'yes' && (
                                            <div className="formRow">
                                                <textarea
                                                    placeholder={"Elenco restrizioni e/o richieste particolari (separati da virgola)"}
                                                    id="intoleranceListInput"
                                                    value={intoleranceList}
                                                    onChange={(e) => setIntoleranceList(e.target.value)}
                                                />
                                            </div>
                                        )}
                                    </>

                                )}
                                <div className="formRow">
                                    <select value={structure} onChange={handleStructureChange} required>
                                        <option value="">Ti serve una struttura per dormire?</option>
                                        <option value="yes">Si</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>

                                <div className="formRow">
                                    <button type="submit">Conferma Presenza</button>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </LoadingOverlay>


    );
};

export default WeddingForm;