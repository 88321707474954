import React from 'react';
import '../styles/information.css'

const Information = () => {
    return (
        <div className='box'>
            <div className='information'>
                <h2>Informazioni generali</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <h3>Cerimonia 💒</h3>
                                <p className="description">La cerimonia inizierà alle ore 17.00 circa. Si svolgerà
                                    all'interno
                                    della chiesetta vegetale, nel cuore del parco. Vi preghiamo di arrivare in anticipo per
                                    permettere il regolare svolgimento dell'evento.</p>
                            </td>
                            <td>
                                <h3>Intolleranze 🍽‍️</h3>
                                <p className="description">Sarà nostra premura garantire un rinfresco e una cena adeguata
                                    per
                                    chi ha intolleranze o allergie. Vi preghiamo di segnalarlo nell'apposita sezione durante
                                    la
                                    fase di conferma della partecipazione.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h3>Piscina 🏊</h3>
                                <p className="description">All'interno della tenuta sarà presente una piscina; tuttavia
                                    non
                                    può essere utilizzata, quindi evitate di buttarvi ubriachi all'interno! 😄</p>
                            </td>
                            <td>
                                <h3>Serata 🎼 </h3>
                                <p className="description">La festa si potrarrà anche dopo cena! Per chi vuole, fino alle 2
                                    di notte
                                    verremo invitati all'interno del Serradesca Club, dove potremo ballare e continuare i
                                    festeggiamenti!</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h3>Dress Code 👗</h3>
                                <p className="description">Nessun dress-code particolare richiesto. Ricordatevi però di non
                                    indossare tacchi troppo alti in quanto
                                    ci saranno molti sassetti: al massimo portatevi un paio di scarpe in più!</p>
                            </td>
                            <td>
                                <h3>Parcheggio 🚘</h3>
                                <p className="description">Un grande parcheggio è presente all'interno della struttura,
                                    quindi nessun problema.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Information;