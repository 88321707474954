import React, { useState } from 'react';
import './../styles/contact.css';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay-ts';
import BeatLoader from 'react-spinners/BeatLoader';

const apiHost = process.env.REACT_APP_API_HOST;

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        console.log('Dati del form:', { name, email, message });
        await axios.post(`${apiHost}/api/v1/wedding/email`, {
            name,
            email,
            message
        });
        setLoading(false);
        setSubmitted(true);
    };

    return (
        <LoadingOverlay
            active={isLoading}
            spinner={<BeatLoader color="#cfba1a" />}
            text='Attendere prego...'
        >
            <div className="contact-us-page">
                <div className="contact-us">
                    {submitted ? (
                        <div>Grazie per averci contattato! Ti risponderemo al più presto.</div>
                    ) : (
                        <>
                            <h2>Contattaci</h2>
                            <form onSubmit={handleSubmit}>
                                <label>
                                    Nome:
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                                </label>
                                <label>
                                    Email:
                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                </label>
                                <label>
                                    Messaggio:
                                    <textarea value={message} onChange={(e) => setMessage(e.target.value)}
                                        required></textarea>
                                </label>
                                <button type="submit">Invia Messaggio</button>
                            </form>
                            <br />
                            <div>Numero di telefono: 3933168741 (Gianpaolo) - 3483424872 (Martina)</div>
                            <div>Email: gianpaolo.caprara@gmail.com / martina.piluso@yahoo.it</div>
                        </>
                    )}
                </div>
            </div>
        </LoadingOverlay>
    );
};

export default Contact;