import React from 'react';
import '../styles/location.css'
import serradesca from '../images/serradesca.png'
import {FaChrome, FaFacebook, FaInstagram} from 'react-icons/fa';
import Weather from "./Weather";

const Location = () => {
    return (
        <div className='box'>
            <div className='location'>
                <div className={'locationInfo'}>
                    <h2>La cerimonia si svolgerà presso:</h2>
                    <p className="locationName">Tenuta Serradesca</p>
                    <p className="address">Via Serradesca, 6, 24020 Scanzorosciate BG</p>
                    <p className="date">Domenica 15 Settembre 2024 - ore 17:00</p>
                    <p className="description">Per raggiungere la struttura vi basterà inserire le indicazioni nel
                        navigatore o usare direttamente la mappa sottostante.</p>
                </div>
                <div className="imageContainer">
                    <img src={serradesca} alt="Serradesca" className="image"/>
                </div>
                <div className="iframeContainer">
                    <iframe
                        className={"iframe"}
                        title={"location"}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2786.120358887124!2d9.76416707679204!3d45.70862221709638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47815a244756cd37%3A0x263a3bc380a77d45!2sTenuta%20Serradesca!5e0!3m2!1sit!2sit!4v1687345597967!5m2!1sit!2sit"
                        allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
                <div className={'locationSocial'}>
                    <Weather city={"Scanzorosciate"}/>
                    <div className={'social'}>
                        <h2>Link utili della struttura:</h2>
                        <a className="address" title="Tenuta Serradesca Sito"
                           href="https://www.acquaroli.it/location-matrimoni/tenuta-serradesca">
                            <FaChrome className="icon web"/> Sito</a><br></br>
                        <a className="address" title="Tenuta Serradesca Facebook"
                           href="https://www.facebook.com/tenutaserradesca/">
                            <FaFacebook className="icon instagram"/> Facebook</a><br></br>
                        <a className="address" title="Tenuta Serradesca Instagram"
                           href="https://www.instagram.com/tenutaserradesca/">
                            <FaInstagram className="icon instagram"/> Instagram</a>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Location;