import React from 'react'
import Header from '../components/Header'
import Countdown from '../components/Countdown';
import OurStory from "../components/OurStory";
import Presentation from "../components/Presentation";
import Footer from "../components/Footer";
import Interlude from "../components/Interlude";

const Home = () => {
    return (
        <div>
            <Header fixed={true} />
            <Presentation />
            <Countdown />
            <Interlude />
            <OurStory />
            <Footer />
        </div>
    );
}

export default Home;