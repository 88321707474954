import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/header.css'
import FavoriteIcon from '@mui/icons-material/Favorite';
import { bubble as Menu } from 'react-burger-menu';

const title = 'Martina & Gianpaolo'
const data = [
    {
        title: 'HOME',
        link: '/',
    },
    {
        title: 'LOCATION',
        link: '/location',
    },
    {
        title: 'CONFERMA PARTECIPAZIONE',
        link: '/confirm',
    },
    {
        title: 'NOTE DEGLI SPOSI',
        link: '/notes',
    },
    {
        title: 'CONTATTACI',
        link: '/contactUs',
    }
];

const Header = ({ fixed }) => {
    const [scroll, setScroll] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        setScroll(scrollTop > 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <header className={`header ${scroll ? 'scroll' : ''} ${fixed ? 'fixed' : ''}`}>
            <div className="header-container">
                {/* Desktop Menu */}
                {screenWidth > 844 && (
                    <>
                        <h1 className="header-title">
                            <a href="/">{title}</a>
                        </h1>
                        <div className={`nav-links`}>
                            {data.map((nav, index) => (
                                <React.Fragment key={index}>
                                    <NavLink to={nav.link} className="nav-link">
                                        {nav.title}
                                    </NavLink>
                                    {index !== data.length - 1 && <FavoriteIcon className="heart-icon" />}
                                </React.Fragment>
                            ))}
                        </div>
                    </>
                )}

                {/* Mobile Menu */}
                {screenWidth <= 844 && (
                    <div>
                        <Menu noOverlay>
                            <h1 className="header-title">
                                <a href="/">{title}</a>
                            </h1>
                            {data.map((nav, index) => (
                                <div key={index}>
                                    <FavoriteIcon className="heart-icon" />
                                    <a id={index} className="menu-item" href={nav.link}>
                                        {nav.title}
                                    </a>
                                </div>
                            ))}
                        </Menu>
                    </div>
                )}
            </div>
        </header >
    );
};

export default Header;