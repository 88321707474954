import React from 'react'
import Header from '../components/Header'
import Iban from "../components/Iban";

const WeddingNotes = () => {
    return (
        <div>
            <Header fixed={false} />
            <Iban />
        </div>
    );
}

export default WeddingNotes;